import './hero-image.scss';

class HeroImage {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-hero-image',
            wrapperClass: '.heroimage--threesixty',
            hrefAttr: 'data-href'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$heroImage = element;

        this.$threeSixty = this.$heroImage.querySelector('[' + this.settings.initAttr + '="threesixty"]');
        this.opened = false;
        this.iframe = undefined;
    }

    initialize () {
        if (this.$threeSixty !== null) {
            this.initThreeSixty();
        }
        window.addEventListener('load', (e) => {
            // this.loadVideos();
            console.log('page is fully loaded');
        });
    }

    loadVideos () {
        const $heroImage = document.querySelector('[data-hero-image="root"]');
        const $allVideos = $heroImage.querySelectorAll('video');
        // const $video = $heroImage.querySelector('video');
        //
        // if ($video) {
        //     const videoUri = $video.getAttribute('data-src');
        //     $video.setAttribute('src', videoUri);
        //     $video.removeAttribute('data-src');
        //     $video.load();
        // }

        $allVideos.forEach(video => {
            const source = video.querySelectorAll('source');
            source.forEach(source => {
                const srcUrl = source.getAttribute('data-src');
                source.setAttribute('src', srcUrl);
                source.removeAttribute('data-src');
                video.play();
            });
        });
    }

    initThreeSixty () {
        this.$wrapper = this.$threeSixty.closest(this.settings.wrapperClass);
        const uri = this.$threeSixty.getAttribute('data-href');

        this.$threeSixty.addEventListener('click', (e) => {
            if (typeof this.$iframe === 'undefined') {
                this.$iframe = this.buildIframe(uri);
                this.$wrapper.append(this.$iframe);
            } else {
                this.$iframe.remove();
                this.$iframe = undefined;
            }
            e.preventDefault();
        });
    }

    buildIframe (uri) {
        this.$iframe = document.createElement('iframe');
        this.$iframe.classList.add('heroimage__iframe');
        this.$iframe.setAttribute('allowfullscreen', true);
        this.$iframe.setAttribute('frameborder', 0);
        this.$iframe.setAttribute('src', uri);

        return this.$iframe;
    }
}

export { HeroImage };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$HeroImage = $context.querySelectorAll('[data-hero-image="root"]');
        for (let i = 0; i < $$HeroImage.length; i++) {
            const $HeroImage = new HeroImage($$HeroImage[i]);
            $HeroImage.initialize();
        }
    }
});
